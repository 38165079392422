import { Media, SubmissionsApi, TextType } from "./types";

type EventsApi = {
  events: {
    id: number;
    name: string;
    date: string;
    location: string;
    summary: string;
    information: {
      abstract?: string;
      extra?: {
        title: string;
        content: string;
      }[];
    };
  }[];
};

// 	YY/MM/DD
export const eventsApi: EventsApi = {
  events: [
    {
      id: 4,
      name: "Curating Gaps",
      date: "23/11/17",
      location: "Toronto",
      summary: "AAA/CASCA Annual meeting “Transitions”",
      information: {
        abstract: `“Curating Gaps” engages with a collection of sounds and silences amassed from Sound Braid’s curators’ everyday field-recordings. Mediated through recording and listening technology, the collection of sounds brings into relief transitions, memory gaps and blurred boundaries. Recordings feature laughs, recorded voices, heavy machinery at a municipal dump, industrial screeches, human heartbeats, and other sonic encounters that probe time, space, and experience. The installation hopes to prompt discussions about leaky in-between spaces by probing what dwells in between sounding and receiving bodies of various sorts.  

“Curating Gaps” is composed of three separate tracks of different lengths. Each track loops, meaning it plays again when it concludes. Because the three looping tracks are of different lengths, new sonic combinations form during the five-hour duration of the piece. “Curating Gaps” will play one and half times during the installation. The installation also provides descriptive text of the sounds emanating through the loudspeaker system. This text is projected onto the screen and rolls out in loops. Like the sonic loops, text loops form different combinations that are meant to spark conversations between looped segments. We hope that the combination of sounds and texts in emerging and ever-developing discussion will generate new insights about mediated environments. `,
      },
    },
    {
      id: 1,
      name: "Sounds of Migration / Collective Listening",
      date: "23/10/07",
      location: "Toronto",
      summary: "LAMAS Latin American Media Arts Symposium",
      information: {
        abstract: `For the Symposium’s closing event, we invite the audience to join us for an embodied experience of deep and attuned listening. The activities will be led by the producers of three sound-based projects that, by looking at human and non-human relationality, approach notions of homecoming, nostalgia, and futurity. We will gather around this array of sonic archives that document, expand, and interweave the experiences of diasporic movements.

        Sound Braid, a collaborative and multi-authored project by Luisa Isidro Herrera, Nicole Marchesseau, Johann Sander Puustusmaa, and Jillian Fulton-Melanson, explores sound, silence, and refusal as elements inherent to human experience. Bernardo García’s interactive project parallels the routes of birds using Tommy Thompson Park as a stopover and those of immigrants arriving in Canada. Seis8s is a web-based computer language created by Luis Navarro that speculates on culturally situated music technology, specifically in the overlapping of algorithmic music and Latin dance music.
        The activity will run for approximately 90 minutes.`,
        extra: [
          {
            title: "Led by",
            content:
              "Bernardo García, Luis Navarro Del Angel, Luisa Isidro Herrera, Nicole Marchesseau, and Johann Sander Puustusmaa.",
          },
          {
            title: "Moderated by",
            content: "Pablo Rincón",
          },
        ],
      },
    },
    {
      id: 2,
      name: "Curating Precarities",
      date: "23/11/19",
      location: "Toronto",
      summary: "AAA/CASCA Annual meeting “Transitions”",
      information: {
        abstract: `This roundtable considers how creative or multimodal spheres of inquiry might act as venues for speculation and attunement enduringly or tenuously tethered to anthropology. Discussions will centre around how novel projects might allow for anthropology's adaptability during transitionary times and the role of the academy in supporting such projects. Panelists will be invited to reflect upon affiliations, methods, and collaborations, and to share projects with which they have been involved.

        Taking participants' creative projects as a point of departure, we question whether precarity in the form of such projects might be considered as conditional, extraneous, or prerequisite ingredients to participation in anthropological milieus. Panelists are also encouraged to reflect upon how such projects might act as springboards for early career anthropologists transitioning into spaces within and beyond academe, and within and beyond anthropology.
        
        Roundtable participants will offer insights from diverse experiences. Discussions of experience will involve exploring scales and depths of collaboration and engagement through multimodality, and the creative affordances made possible through the examination of multispecies mobilities and artivism within social science research. Geographical foci shift between specific sites such as Beirut and Bangalore, along with distinct locations in Europe, North and South America, and spaces of intersection.`,
        extra: [
          {
            title: "Roundtable participants",
            content:
              "Columba Gonzales-Duarte, Anand Pandian, Fiona Murphy, Alexandrine Boudreault-Fournier, Sara O’Rourke, Luisa Isidro Herrera, Nicole Marchesseau, Johann Sander Puustusmaa",
          },
        ],
      },
    },
    {
      id: 3,
      name: "Sound Braiding",
      date: "23/05/05",
      location: "Edmonton",
      summary:
        "SpokenWeb’s 2023 Symposium and Institute “REVERB: Echo-Locations of Sound and Space”",
      information: {
        abstract: `Sound Braid (https://www.soundbraid.org/) is an emergent web platform committed to exploring incommensurable spaces rooted in captured and ongoing sonic experiences that are at least somewhat remote from expressions of human language or music. Braid collaborators—others interested in exploring sonic worlding from diverse perspectives—will be invited to react with previous posters as moments of sound hosted on the braid plait with possible emanations.

        In contrast to the shock and urgency of much of today’s digital life, by opening a gradually self-formulating and non-predictive space, Sound Braid will provide room for thoughtful listening, contemplation, and inquiry into what sound is doing. Participants will be introduced to the Sound Braid platform, and then will be invited to collectively workshop a sonic thread response—or numerous responses—to an existing thread/s.
        
        The workshop will involve a 15-minute introduction, 30 minutes of practical workshopping and 30 minutes of discussion based on the practical component of the workshop (handheld recording devices or phones can be useful for the practical activity, but are not a requirement).`,
      },
    },
  ],
};

export const submissionsApi: SubmissionsApi = {
  submissions: [
    {
      id: 19,
      submissionTitle: "Sonic Markers of Santiago de Cuba",
      author: "Carlo Cubero, Pablo D. Herrera Veitia, and Kevin Molloy",
      submissionDate: "09-25-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695731642/Sept%2025%20sounds/Sonic_markers_Cuba_l2szcx.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            ` This sonic ethnography is a rendering of specific spatio-temporal "marks" of Santiago de Cuba. The result is a sonic collage that invites the listener to assume a reflective stance and associate connections between sound, space, and time. 
            
            The materials for this sound piece were produced in Santiago de Cuba in collaboration with local musicians and sound artists. This sonic ethnography addresses the intimate spaces of Santiago de Cuba. Intimacy, in this context, points to improvised spaces that generate immanent experiences. Thematically, the work engages with the rhythms and sonic tones of the city and invites listeners to consider the city’s symphonic qualities. 
            
            #Sonic spaces #city symphony #post-socialist studies #ethnography `,
          ],
        },
      ],
    },
    {
      id: 18,
      submissionTitle: "A World Cup Memoir from Casablanca",
      author: "Jillian Fulton-Melanson",
      submissionDate: "09-25-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695647417/Sept%2025%20sounds/World_Cup_in_Casablanca-_Morocco_vs_Canada_Match_-_2023-08-19_10.36_AM_1_kz27jx.wav",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            "A compilation of sounds inside a local pub in Casablanca and on the streets of the city’s downtown core. In this sound walk, intense chatter, critique, excitement, and national cries of joy through wedding songs and ululation can be heard in the climactic moment where the Atlas Lions of Morocco defeated Canada’s national team and advanced from the Group Stage to the Round of 16 at the Fifa World Cup in December 2022, where they made national and regional history.",
          ],
        },
      ],
    },
    {
      id: 17,
      submissionTitle: "Sound walk",
      author: "Vaibhav Sawhney",
      submissionDate: "09-25-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695647394/Sept%2025%20sounds/Soundwalk1_-_2023-02-07_12.17_AM_1_ix3dx9.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            "This sound walk is a colourful portrayal of South Asian societal norms in Jalandhar city of Northern India. Speaking to the anthropology of the everyday, it is comprised of sounds from vendors, religious institutions, and other day-to-day activities. Gender dynamics that prevail in the sub-continent act as a rolling ostinato through the continuous, active male presence of a handyman, a priest reciting prayers, and a merchant selling candy. There is an absolute absence of feminine sounds. On one hand, these sounds construct the soundscape of a regular street in India where themes of social class, marginalization, and migration emerge through the accents and dialects of each of the speakers. On the other hand, these sounds conjure memories that enact sentiments of nostalgia for my home and childhood.",
          ],
        },
      ],
    },
    {
      id: 16,
      submissionTitle: "Morning / nightbirds",
      callTag: "60-second sounds",
      author: "Pedro Marra",
      submissionDate: "04-16-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600595/Sounds/Marra_morning_night_birds_yk3nfb_xskteo.wav",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            "The songs of three birds resound and rhythmically intertwine at midnight. One of them, the Suiriri (Tyrannus melancholicus) uses to sing during the day. The other two sing at night. The coexistence of those recorded bird songs evince a sonic ecology created by human action: Suiriris in Brazilian big cities started to sing at night because of visual pollution.",
          ],
        },
      ],
    },
    {
      id: 15,
      submissionTitle: "Untitled",
      callTag: "60-second sounds",
      author: "Owen Kurtz",
      submissionDate: "04-16-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600602/Sounds/60_Seconds_of_Owen_Kurtz_on_April_11_2023_qelt4o_g9ab4j.wav",
        },
      ],
    },
    {
      id: 14,
      submissionTitle: "Little India’s Sunday Story",
      callTag: "60-second sounds",
      author: "Khadeeja Amenda",
      submissionDate: "04-16-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600555/Sounds/Amenda_Sound_Braids_Little_India_riagiq_ehfr9h.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            "There is a Little India story for all Indians who have moved to Singapore. Mine started with the search for rice. The nearby supermarkets had all the varieties from Jasmine to Sticky. They were all strange in taste and texture. A friend took me to Little India- we bought rice and had puttu with kadala curry (a South Indian dish). This recording is a Sunday story of Little India, with all its music and chaos. ",
          ],
        },
      ],
    },
    {
      id: 13,
      submissionTitle: "Untitled",
      callTag: "60-second sounds",
      author: "Emjay Wright",
      submissionDate: "04-16-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600555/Sounds/Emjay_sound_braid_ic4jjv_r8nj3l.mp3",
        },
      ],
    },
    {
      id: 12,
      submissionTitle: "Woodpecker Snare",
      callTag: "60-second sounds",
      author: "Nicole Marchesseau",
      submissionDate: "04-16-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600577/Sounds/Marchesseau_Woodpecker_snare_hupo7d_yotq8p.wav",
        },
      ],
    },
    {
      id: 8,
      submissionTitle: "Sonographies",
      author: "Anne Dubos",
      submissionDate: "01-27-2023",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600628/Sounds/Dubos_pedn2t_ylwvom.wav",
        },
        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600191/Images/Dubos_sonographies_yfmb5v_fsj3ns.jpg",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            ` Since 2020, I regularly proceed of soundfield recordings in the Natural Regional park of The Pilat (near Lyon, in France). My location selection varies from what I want to see, to places that I find interesting to listen to. 
            
            In parallel to the recording process, I use certain Deeplistening techniques described by the composer Pauline Oliveiros (2005). Writing the effects of sounds on my own perception of nature that evolves around me changes the sound perception that I have of the place and the moment. Also, I try to experiment, and question what it might be like, to «dwell» here like a like a bird, like a river or like a tree. 
            
            What shocks me, every time I stop for a recording session, is that I hear at least two to three planes passing above me (us). On all the recordings, beyond the fact that we live in natural protected zones, the «anthropo-scene» can only reveal its very dominant presence. But how does it feel to be a «protected» species of frog living in such a noisy environment? Even though we tend to protect «nature» under various labels (such as Natura 2000 Zone), we fail to leave in peace. The mere presence of the engines testifies to our inability to remain silent, or at least, quiet. 
            
            Therefore I wonder: what the world could be like if we considered «silence» as common right?
            
            * all the terms in inverted commas are meant to be discussed.
            #Sonographies #soundmap #Pilat #ParcNaturelRegional #Deeplistening #silence #commons`,
          ],
        },
      ],
    },
    {
      id: 9,
      submissionTitle: "The Bridge at Lundbreck Falls",
      author: "Mickey Vallee",
      submissionDate: "01-27-2023",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600559/Sounds/Vallee_zpcwxk_uu5k9g.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            `When a train passes a crossing, it must sound out the following pattern at a quarter mile ahead as a warning: two long blasts, one short, one more long. Alongside ringing bells, the horn at the recording’s opening is the second of two long blasts, as it heaves its fill around the corner of a narrow mountain corridor. When it clears the opening, it charges ahead as though it were let out of a gate, arms stretched and mouth agape: one clear, short blast, followed by one more long as it crosses. The mechanical beast’s wailings are followed by the serenity of its movements as it charges by. What at first is a “blooming, buzzing confusion” (how William James described the complexity of the manifold) settles into a distinguishable pattern of calls and responses across the metallic folds of alternators, generators, fuel cells, transformers, inverters, rectifiers, pantographs, collectors, traction motors, convertors, sleepers, fishplates, fasteners, spikes, gravel, the weight of potash gently heaving side to side. A persistently rising drone emerges from within these interlocking entities. It has a property that none of its parts seem to have on their own. It resembles a Shepard tone, eternally climbing yet eternally remaining, simultaneously assembling and disassembling a clear and distinct state. When a train passes a crossing, it is a moment, inhabited by an infinite series of movements.`,
          ],
        },
      ],
    },
    {
      id: 10,
      submissionTitle: "Pico",
      author: "Jorge V. Castañeda Ochoa",
      submissionDate: "01-27-2023",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600569/Sounds/Pico_lnqo8j_f3vvsa.mp3",
        },
        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600207/Images/Pico_h1a0tv_tn5qxk.jpg",
        },
      ],
    },
    {
      id: 11,
      submissionTitle: "Soothing noise",
      author: "Aparna Sengupta",
      submissionDate: "01-27-2023",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600555/Sounds/Sound__Aparna_Sengupta_azmzyk_foxkts.mp3",
        },
        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600192/Images/Image_Aparna_Sengupta_wqfk5x_thobtm.jpg",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            `Keywords: Peaceful rhythms; nature; free flowing; tranquility; deep body-mind connect; freedom`,
          ],
        },
      ],
    },
    {
      id: 1,
      submissionTitle: "Fisherman's day",
      author: "Johann Sander Puustusmaa",
      submissionDate: "02-10-2022",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600565/Sounds/Fisherman_1_igblst_h6feg7.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            `Patterns of work, unsupervised, unguided. Working for what? Hollow rhythms. What are they building over there? Play and play again. `,
          ],
        },
      ],
    },
    {
      id: 2,
      submissionTitle: "Land of Nobody",
      author: "Nicole Marchesseau",
      submissionDate: "02-10-2022",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600580/Sounds/Land_of_Nobody_k4gdzc_udxsu5.mp3",
        },

        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600204/Images/DSC04127_1_spbxu8_srgdle.jpg",
        },

        {
          title: "",
          mediaType: Media.text,
          textType: TextType.poem,
          textTitle: "Land of Nobody",
          textBody: [
            `captured field recorder sounds 
          
juxtapose with mottled impressions 

of a derelict facility and quarry



words mask feet 

atop gravel 

and glass shards

pausing at 

tamped-down patches 

of long grasses 

respite for deer

hesitating at

homeless encampment traces

and blasted-open rocky landscapes 

sounds move 

silences emerge

turbine buzzing disappears

along with dialogue



re-listenings jog and rejig 

imprints of spaces 

renew themselves

as gaps open and close



greyed recollections of time

extend beyond 

hemmed-in memories

of sensory experience`,
          ],
        },
      ],
    },
    {
      id: 6,
      submissionTitle: "The Bravery of a Bereavement Leave",
      author: "Luisa Isidro Herrera",
      submissionDate: "10-27-2022",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600561/Sounds/The_bravery_of_a_bereavement_leave_jlongs_fmc6nf.mp3",
        },
      ],
    },
    {
      id: 3,
      submissionTitle: "Laugh Study / Working through Gaps",
      author: "Nicole Marchesseau",
      submissionDate: "10-10-2022",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600564/Sounds/Laugh_tlu6cp_rd4xwu.mp3",
        },
        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600205/Images/DSC04138_1_xtkbnp_adjixl.jpg",
        },

        {
          title: "",
          mediaType: Media.text,
          textTitle: "Laugh Study/Working through Gaps",
          textType: TextType.paragraph,
          textBody: [
            `Laughter rushes from precarious phrases and sputtery releases, vibrating with transient elastic mimicry, rippling through surrounding spaces. It ruptures tensions, releases silent moments of shared uncomfortableness, and sends shivers through uneasy tautness, dissipating intensity through the small city’s liminal spaces. Bubbling through coded awkwardness and transgressing sensory and affective registers, laughter bursts, spring-loaded from our bodies, emerging from uncanny perceptions of the stench of the still-operating abattoir, discussions of absurdity with respect to connections between surnames like Basher and Wright, odd mirrorings between names and practices, providing unintended yet not inappropriate reprieve from difficult discussions. Braided with storytelling and sensation, our laughter audibly nods to mutual and ineffable moments. “Laugh Study” presents laughter out of context. Perhaps it gestures towards the ineffability of experience. Perhaps it will prompt questions about the conditions leading to its emergence and decay, or even pry open an ethics of laughter.
          `,
          ],
        },
      ],
    },
    {
      id: 4,
      submissionTitle: "Ground",
      author: "Johann Sander Puustusmaa",
      submissionDate: "10-10-2022",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600585/Sounds/Ground_gcaw3z_bl1opr.mp3",
        },
        {
          title: "",
          mediaType: Media.text,
          textType: TextType.paragraph,
          textBody: [
            `A story of the past. A made-up story. Wishful thinking, grounded in near-impossible growth. Shifting and pushing upwards for years.
          `,
          ],
        },
      ],
    },
    {
      id: 5,
      submissionTitle: "Quarry",
      author: "Nicole Marchesseau",
      submissionDate: "10-10-2022",

      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600581/Sounds/Quarry_blh77l_zvpeaj.mp3",
        },
        {
          title: "",
          mediaType: Media.image,
          url: "https://res.cloudinary.com/dlpnk8lgg/image/upload/v1695600255/Images/Quarry/DSC04143_t8hb2o_rhzsxy.jpg",
        },
        {
          title: "",
          mediaType: Media.text,
          textTitle: "Quarry",
          textType: TextType.paragraph,
          textBody: [
            `We drift atop a surface of solid grey limestone, shifting shale, broken glass, and lichen threading its way up through cracked rock. Rusted out containers and discarded spent fireworks, taped together like burned-out bouquets, punctuate the perimeter. A stranger’s song drifts down from a hidden perch within the ledge and hangs incongruously. What lines are followed when feet push rocks aside in an ancient, made anew landscape, one several times abandoned and slated to soon change. And change again. And change again.
            `,
          ],
        },
      ],
    },
    {
      id: 7,
      submissionTitle: "Pachamanka in Manitoba",
      author: "Luisa Isidro Herrera",
      submissionDate: "11-26-2022",
      submissionContent: [
        {
          title: "",
          mediaType: Media.sound,
          url: "https://res.cloudinary.com/dlpnk8lgg/video/upload/v1695600580/Sounds/Pachamanka_in_Manitoba_hwu1rx_z3vx2c.mp3",
        },
      ],
    },
  ],
};
