import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./AudioPlayer.module.scss";
import PlayButtonIcon from "../../icons/PlayButtonIcon";

interface AudioPlayerProps {
  url: string;
  audioPlayerWidth?: string;
  className?: string;
  isPlayingOnLoad?: boolean;
}

const AudioPlayer = ({
  url,
  audioPlayerWidth,
  className,
  isPlayingOnLoad,
}: AudioPlayerProps) => {
  // TODO: change typing
  const audioPlayer = useRef<any>();
  const progressBar = useRef<any>();
  const animationRef = useRef<any>();

  const [isPlaying, setIsPlaying] = useState(isPlayingOnLoad || false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    setCurrentTime(progressBar.current.value);
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current?.duration);
    setDuration(seconds);

    progressBar.current.max = seconds;

    if (isPlaying) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
      return;
    }

    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
  }, [isPlaying, progressBar, audioPlayer, audioPlayer.current?.duration]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    setCurrentTime(progressBar.current.value);
  };

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  return (
    <div className={styles.audioPlayer} style={{ width: audioPlayerWidth }}>
      <audio ref={audioPlayer} src={url} />
      <div className={styles.audioPlayerWrapper}>
        <div className={styles.timeWrapper}>
          <div>{calculateTime(currentTime)}</div>
          <div>{isNaN(duration) ? "" : calculateTime(duration)}</div>
        </div>
        <input
          ref={progressBar}
          type="range"
          className={cn(styles.progressBar, className)}
          style={{ width: audioPlayerWidth }}
          defaultValue="0"
          onChange={changeRange}
        />

        <button
          onClick={() => setIsPlaying(!isPlaying)}
          className={styles.playPauseButton}
        >
          {isPlaying ? (
            <div className={styles.pauseButton}>
              <div />
              <div />
            </div>
          ) : (
            <PlayButtonIcon className={styles.playButton} />
          )}
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;
