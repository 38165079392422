import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./NavBar.module.scss";

const Navbar = () => {
  const { pathname } = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  // this will change in the future
  const isDarkMode = pathname === "/call-for-sounds";

  return (
    <>
      <nav className={styles.hamburgerNav}>
        <button
          className={styles.menuBarButton}
          onClick={() => setIsNavOpen(true)}
        >
          <div
            className={cn(styles.menuBar, {
              [styles.darkMode]: isDarkMode,
            })}
          />
          <div
            className={cn(styles.menuBar, {
              [styles.darkMode]: isDarkMode,
            })}
          />
          <div
            className={cn(styles.menuBar, {
              [styles.darkMode]: isDarkMode,
            })}
          />
        </button>
      </nav>

      {isNavOpen && (
        <div className={styles.menuOverlay}>
          <div className={styles.menuOverlayWrapper}>
            <button
              className={styles.menuOverlayWrapperButton}
              onClick={() => setIsNavOpen(false)}
            >
              <span>X</span>
            </button>

            <div className={styles.mobileNavWrapper}>
              <Link
                className={cn(styles.navItem, {
                  [styles.active]: pathname === "/",
                })}
                to="/"
              >
                Home
              </Link>

              <Link
                className={cn(styles.navItem, {
                  [styles.active]: pathname === "/submit",
                })}
                to="/submit"
              >
                Submit
              </Link>

              <Link
                className={cn(styles.navItem, {
                  [styles.active]: pathname === "/thread",
                })}
                to="/thread"
              >
                Thread
              </Link>

              <Link
                className={cn(styles.navItem, {
                  [styles.active]: pathname === "/events",
                })}
                to="/events"
              >
                Events
              </Link>

              <Link
                className={cn(styles.navItem, {
                  [styles.active]: pathname === "/about-us",
                })}
                to="/about-us"
              >
                About us
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* desktop view */}
      <nav className={styles.nav}>
        <Link
          className={cn(styles.navItem, {
            [styles.active]: pathname === "/",
            [styles.darkMode]: isDarkMode,
          })}
          to="/"
        >
          Home
        </Link>

        <Link
          className={cn(styles.navItem, {
            [styles.active]: pathname === "/submit",
            [styles.darkMode]: isDarkMode,
          })}
          to="/submit"
        >
          Submit
        </Link>

        <Link
          className={cn(styles.navItem, {
            [styles.active]: pathname === "/thread",
            [styles.darkMode]: isDarkMode,
          })}
          to="/thread"
        >
          Thread
        </Link>

        <Link
          className={cn(styles.navItem, {
            [styles.active]: pathname === "/events",
            [styles.darkMode]: isDarkMode,
          })}
          to="/events"
        >
          Events
        </Link>

        <Link
          className={cn(styles.navItem, {
            [styles.active]: pathname === "/about-us",
            [styles.darkMode]: isDarkMode,
          })}
          to="/about-us"
        >
          About us
        </Link>
      </nav>
    </>
  );
};

export default Navbar;
