import React from "react";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import styles from "./SubmissionsPage.module.scss";

const SubmissionsPage = () => (
  <Layout>
    <Navbar />
    <div className={styles.textWrapper}>
      <div>
        <section className={styles.bioSection}>
          <p>
            Sound braid invites submissions that imagine, imply, ignore, follow
            up, react, or respond to existing posts. Before submitting, consider
            whether your contribution serves as a beginning, middle, or an end,
            an interruption, explanation, or a solution. In the thread space,
            though collaborators' contributions braid into timelines, the idea
            of the braid is that curated sounds will also connect non-linearly
            to other parts of the emerging sonic shape. The nature of Sound
            braid invites revisiting or reacting to one’s own or other
            collaborators’ work.
          </p>

          <p>
            Submissions will be lightly reviewed, and new posts will appear
            monthly. You may indicate if there is a particular thread you wish
            to respond to or whether you wish to generate a new thread. If you
            are responding to a particular thread or are generating a beginning,
            inform curators when emailing your submission. If you do not wish to
            specify, it will be curated for you.
          </p>
          <p>
            As a space of discovery, generation and interdisciplinarity, Sound
            braid aims to ignite relationships, discussions, and collaboration.
            We invite sounds that might provide indexations of time, place, or
            space, or that might resist them. We hope that the space will engage
            collaborators in attunement and speculation.
          </p>
        </section>
        <section className={styles.section}>
          <h3 className={styles.ethicsHeader}>Ethics</h3>
          <p>
            Please take into consideration ethical guidelines that may be
            relevant to your affiliations when submitting material. If
            applicable, review and adhere to ethical guidelines provided by any
            professional organizations to which you belong before making your
            submission. Sound braid will not be responsible for compliance
            failures with ethics board recommendations.
          </p>
          <p>
            Sound braid will approve and remove submissions at our discretion.
            We respect ‘the right to be forgotten’ and will remove digital
            traces of posts on the site and its affiliates (social media) within
            reason, if requested.
          </p>

          <p>
            We hope to keep conversations open and to generate responses with
            light moderation, though we realize that this approach carries with
            it certain complications. We encourage contributors to consider the
            ownership of submitted sounds, the beneficiaries of recordings and
            their circulation, what sounds are reproducing, how sounds might
            traverse horizontally across geographical areas, and how capturing
            and reproducing sounds might slip into extractivist logics.
          </p>
          <p>
            Contributors might also consider how sounds represent or
            misrepresent human and non-human subjects, how they might other, or
            what they and their capture might otherwise be doing beyond the
            visible and audible sphere of this platform. We will keep these
            matters in mind too as the braid manifests.
          </p>
        </section>
      </div>

      <section className={styles.submissionsSection}>
        <h2>How to submit</h2>
        <p>
          We invite contributions that could fit in 10 minutes, and the
          accompanying 250 words.
        </p>
        <p>
          <span className={styles.subHeader}>Sound contributions</span> - Time
          limit of 10 minutes. .WAV or .MP3
        </p>

        <p>
          <span className={styles.subHeader}>Written work</span> – up to 250
          words or 4-5 tags/keywords
        </p>

        <p>Optional image to accompany the sound (700x700px)</p>

        <p>
          Interdisciplinarity and combining different formats is not only
          welcomed, but also encouraged. The nature of Sound Braid invites
          revisiting or reacting to one’s own or other collaborators’ work.
        </p>

        <div className={styles.emailWrapper}>
          <h3>Send your submissions</h3>
          <a
            href="mailto:info.soundbraid@gmail.com"
            className={styles.emailButton}
          >
            info.soundbraid@gmail.com
          </a>
        </div>

        <p className={styles.smallPrint}>
          Submissions published on the 15th of every month
        </p>
      </section>
    </div>
  </Layout>
);

export default SubmissionsPage;
