import React from "react";
import { useNavigate } from "react-router-dom";
import { Submission } from "../../utils/types";
import styles from "./ThreadPageItem.module.scss";

type ThreadPageItemProps = {
  submission: Submission;
};

const ThreadPageItem = ({ submission }: ThreadPageItemProps) => {
  const { id, author, submissionTitle, callTag } = submission;
  const navigate = useNavigate();

  return (
    <li
      key={id}
      className={styles.listItem}
      onClick={() => navigate(`/submission/${id}`)}
      tabIndex={0}
    >
      <div>
        <p className={styles.title}>{submissionTitle}</p>
        <p className={styles.author}>{author}</p>
      </div>
      {callTag && <span className={styles.tag}>{callTag}</span>}
    </li>
  );
};

export default ThreadPageItem;
