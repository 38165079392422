import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";

import { eventsApi } from "../utils/api";
import styles from "./EventsPage.module.scss";
import {
  endOfDay,
  format,
  isFuture,
  isSameDay,
  isToday,
  parse,
} from "date-fns";

const EventsPage = () => {
  const navigate = useNavigate();
  const { events } = eventsApi;

  const formattedData = events.map((event) => {
    const date = parse(event.date, "yy/MM/dd", new Date());

    return { ...event, date };
  });

  const today = format(new Date(), "yy/MM/dd");
  const formattedToday = endOfDay(parse(today, "yy/MM/dd", new Date()));

  const pastEvents = formattedData.filter(
    (event) =>
      !isSameDay(endOfDay(event.date), formattedToday) &&
      !isFuture(new Date(event.date))
  );

  const futureEvents = formattedData
    .filter((event) => isFuture(new Date(event.date)))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const todayEvents = formattedData.filter((event) => isToday(event.date));

  return (
    <Layout>
      <Navbar />
      <div className={styles.wrapper}>
        {todayEvents.length > 0 && (
          <span>
            <h1 className={styles.header}>Happening today</h1>

            <div className={styles.eventsListWrapper}>
              {todayEvents.map(({ id, name, summary, date, location }) => (
                <button
                  tabIndex={0}
                  key={id}
                  onClick={() => navigate(`/event/${id}`)}
                  className={styles.eventWrapper}
                >
                  <div className={styles.eventInfoWrapper}>
                    <h2>{name}</h2>
                    <p>{summary}</p>

                    <span className={styles.dateAndLocation}>
                      <span>{format(new Date(date), "LLLL dd, yyyy")}</span>
                      <span>|</span>

                      <span>{location}</span>
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </span>
        )}
        {futureEvents.length > 0 && (
          <span>
            <h1 className={styles.header}>Future events</h1>

            <div className={styles.eventsListWrapper}>
              {futureEvents.map(({ id, name, summary, date, location }) => (
                <button
                  tabIndex={0}
                  key={id}
                  onClick={() => navigate(`/event/${id}`)}
                  className={styles.eventWrapper}
                >
                  <div className={styles.eventInfoWrapper}>
                    <h2>{name}</h2>
                    <p>{summary}</p>

                    <span className={styles.dateAndLocation}>
                      <span>{format(new Date(date), "LLLL dd, yyyy")}</span>
                      <span>|</span>
                      <span>{location}</span>
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </span>
        )}

        {pastEvents.length > 0 && (
          <span>
            <h1 className={styles.header}>Past events</h1>
            <div className={styles.eventsListWrapper}>
              {pastEvents.map(({ id, name, summary, date, location }) => (
                <button
                  tabIndex={0}
                  key={id}
                  onClick={() => navigate(`/event/${id}`)}
                  className={styles.eventWrapper}
                >
                  <div className={styles.eventInfoWrapper}>
                    <h2>{name}</h2>
                    <p>{summary}</p>

                    <span className={styles.dateAndLocation}>
                      <span>{format(new Date(date), "LLLL dd, yyyy")}</span>
                      <span>|</span>
                      <span>{location}</span>
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </span>
        )}
      </div>
    </Layout>
  );
};

export default EventsPage;
