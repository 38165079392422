export enum Media {
  image = "image",
  sound = "sound",
  text = "text",
}

export enum TextType {
  poem = "poem",
  paragraph = "paragraph",
}

export type Api = {
  author: string;
  mediaType: Media;
  url: string;
  submissionTitle: string;
  mediaTitle?: string;
  submissionDate: string;
};

export type SubmissionContent = {
  title?: string;
  mediaType: Media;
  textType?: TextType;
  url?: string;
  textBody?: string[];
  textTitle?: string;
};

export interface Submission {
  id: number;
  author: string;
  submissionTitle: string;
  callTag?: string;
  mediaTitle?: string;
  submissionDate: string;
  submissionContent: SubmissionContent[];
}

export interface SubmissionsApi {
  submissions: Submission[];
}
