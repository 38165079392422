import React from "react";
import AudioPlayer from "../components/AudioPlayer/AudioPlayer";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import EmailIcon from "../icons/EmailIcon";

import styles from "./CallForSoundsPage.module.scss";

const CallForSoundsPage = () => (
  <div className={styles.container}>
    <Layout>
      <Navbar />
      <div className={styles.callForSoundsPageWrapper}>
        <h1 className={styles.header}>Call for 60-second sounds</h1>

        <div className={styles.contentWrapper}>
          <div className={styles.textWrapper}>
            <p>
              Sound braid is inviting a new round of 60-second sounds, a
              timeframe meant to provoke questions about urgency or
              predictability. Is it worth creating a 60-second sound piece about
              something that happened in the past? What sorts of stories might
              be told through 60 seconds? How do 60 seconds enable or limit
              temporal engagement?
            </p>
            <p className={styles.deadlineText}>
              Deadline for submissions: April 15th
            </p>
          </div>

          <div className={styles.soundWrapper}>
            <p>
              As an option, you may send a 60-word text to accompany the sound
            </p>

            <div className={styles.audioPlayerWrapper}>
              <AudioPlayer
                url="https://res.cloudinary.com/ds4aubvc7/video/upload/v1678381691/sounds/1minutecallforsounds_ecpww3.wav"
                className={styles.audio}
              />
            </div>
          </div>

          <div className={styles.contactInfoWrapper}>
            <div className={styles.emailWrapper}>
              <h3 className={styles.emailHeader}>Send your sounds to:</h3>
              <span className={styles.email}>
                <p>info.soundbraid@gmail.com</p>
                <a
                  className={styles.emailButton}
                  href="mailto:info.soundbraid@gmail.com"
                >
                  <EmailIcon />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
);

export default CallForSoundsPage;
