import React, { useState } from "react";
import cn from "classnames";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import { submissionsApi } from "../utils/api";
import { Media, SubmissionContent, TextType } from "../utils/types";
import styles from "./HomePage.module.scss";
import AudioPlayer from "../components/AudioPlayer/AudioPlayer";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";

const HomePage = () => {
  const [visibleSubmissionIndex, setVisibleSubmissionIndex] = useState(0);
  const { submissions: subsFromApi } = submissionsApi;
  const navigate = useNavigate();

  const submissions = subsFromApi.slice(0, 10);

  const showSubmissionContent = (
    submission: SubmissionContent,
    idx: number
  ) => {
    const { mediaType, title, url, textBody, textType } = submission;

    if (mediaType === Media.image) {
      return <img key={idx} className={styles.image} src={url} alt={title} />;
    }

    if (mediaType === Media.sound) {
      return (
        <div key={idx} className={styles.soundWrapper}>
          <h5>{submissions[visibleSubmissionIndex].submissionTitle}</h5>
          <AudioPlayer url={url || ""} className={styles.audioPlayer} />
        </div>
      );
    }

    if (mediaType === Media.text) {
      return (
        <div key={idx} className={styles.textWrapper}>
          <div
            className={cn(styles.text, {
              [styles.poem]: textType === TextType.poem,
            })}
          >
            {textBody?.map((paragraph, idx) => (
              <p
                key={idx}
                className={cn(styles.paragraph, {
                  [styles.poemText]: textType === TextType.poem,
                })}
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <Layout>
      <Navbar />
      <div className={styles.wrapper}>
        <div>
          <div className={styles.submissionHeaderWrapper}>
            <p className={styles.submission}>Submission:</p>

            <button
              className={styles.submissionTitle}
              onClick={() =>
                navigate(
                  `/submission/${submissions[visibleSubmissionIndex].id}`
                )
              }
            >
              {submissions[visibleSubmissionIndex].submissionTitle}
            </button>

            <p className={styles.author}>
              {submissions[visibleSubmissionIndex].author}
            </p>
            {submissions[visibleSubmissionIndex].callTag && (
              <Link
                to="/thread?search_text=60-second"
                className={styles.callTag}
              >
                {submissions[visibleSubmissionIndex].callTag}
              </Link>
            )}
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.contentWrapper}>
              {submissions[visibleSubmissionIndex].submissionContent.map(
                (submission, idx) => showSubmissionContent(submission, idx)
              )}
            </div>
          </div>
          <div className={styles.navButtonsWrapper}>
            <button
              onClick={() => {
                setVisibleSubmissionIndex(visibleSubmissionIndex - 1);
              }}
              className={cn(styles.previousButton, {
                [styles.disabled]: visibleSubmissionIndex === 0,
              })}
              disabled={visibleSubmissionIndex === 0}
            >
              <ArrowLeftIcon
                className={cn(styles.arrowLeft, {
                  [styles.disabled]: visibleSubmissionIndex === 0,
                })}
              />
              <p>Previous</p>
            </button>

            <div className={styles.counter}>{`${visibleSubmissionIndex + 1}/${
              submissions.length
            }`}</div>

            {visibleSubmissionIndex === submissions.length - 1 ? (
              <button
                className={styles.nextButton}
                onClick={() => navigate("/thread")}
              >
                <p>Thread</p>
                <ArrowRightIcon className={styles.arrowRight} />
              </button>
            ) : (
              <button
                onClick={() => {
                  setVisibleSubmissionIndex(visibleSubmissionIndex + 1);
                }}
                className={styles.nextButton}
              >
                <p>Next</p>

                <ArrowRightIcon className={styles.arrowRight} />
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
