import React, { ReactNode } from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import styles from "./Layout.module.scss";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.soundWrapper}>
          <p
            className={cn(styles.sound, {
              [styles.darkMode]: pathname === "/call-for-sounds",
            })}
          >
            Sound
          </p>
        </div>
        <p
          className={cn(styles.braid, {
            [styles.darkMode]: pathname === "/call-for-sounds",
          })}
        >
          Braid
        </p>
      </div>

      {children}
    </div>
  );
};

export default Layout;
