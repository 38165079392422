import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import AudioPlayer from "../components/AudioPlayer/AudioPlayer";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import { submissionsApi } from "../utils/api";
import { Media } from "../utils/types";

import styles from "./SubmissionPage.module.scss";

const SubmissionPage = () => {
  const { submissionId } = useParams();
  const { submissions } = submissionsApi;
  const navigate = useNavigate();

  const currentSubmission = submissions.find(
    (sub) => sub.id === Number(submissionId)
  );

  if (!currentSubmission) {
    return null;
  }

  document.body.scrollTop = document.documentElement.scrollTop = 0;

  const { author, submissionTitle, submissionContent } = currentSubmission;

  const hasOnlySoundSub =
    submissionContent.length === 1 &&
    submissionContent[0].mediaType === Media.sound;

  const soundContent = submissionContent.filter(
    ({ mediaType }) => mediaType === Media.sound
  );
  const imageContent = submissionContent.filter(
    ({ mediaType }) => mediaType === Media.image
  );
  const textContent = submissionContent.filter(
    ({ mediaType }) => mediaType === Media.text
  );

  return (
    <Layout>
      <Navbar />

      <div className={styles.container}>
        <div className={styles.submissionHeaderWrapper}>
          <button
            onClick={() => {
              navigate("/thread");
            }}
            className={styles.threadButton}
          >
            <ArrowLeftIcon className={styles.arrowLeft} />
            <p>Thread</p>
          </button>

          <p className={styles.submissionTitle}>{submissionTitle}</p>
          <p className={styles.author}>{author}</p>
        </div>

        <div
          className={cn(styles.contentWrapper, {
            [styles.onlySound]: hasOnlySoundSub,
          })}
        >
          {hasOnlySoundSub ? (
            <div className={styles.soundWrapper}>
              <AudioPlayer
                url={submissionContent[0].url || ""}
                className={cn(styles.audioPlayer, {
                  [styles.solo]: hasOnlySoundSub,
                })}
              />
            </div>
          ) : (
            <div className={styles.wrapper}>
              {soundContent.map(({ url }, idx) => (
                <AudioPlayer
                  key={idx}
                  url={url || ""}
                  className={styles.audioPlayer}
                />
              ))}

              <div
                className={cn(styles.imageAndTextWrapper, {
                  [styles.onlyText]:
                    imageContent.length === 0 && textContent.length > 0,
                })}
              >
                <div className={styles.images}>
                  {imageContent.map(({ url }, idx) => (
                    <img key={idx} className={styles.image} src={url} alt="" />
                  ))}
                </div>

                <div className={styles.texts}>
                  {textContent.map(({ textBody }, idx) => (
                    <p key={idx}>{textBody}</p>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SubmissionPage;
