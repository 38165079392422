import React from "react";

type SearchIconProps = { className?: string };

const SearchIcon = ({ className }: SearchIconProps) => (
  <svg
    className={className}
    width="34"
    height="36"
    viewBox="0 0 34 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="20.1713"
      y1="20.063"
      x2="32.1713"
      y2="35.063"
      stroke="#930D0D"
      strokeWidth="3"
    />
    <circle
      cx="12.5"
      cy="12.5"
      r="11"
      fill="#FCF6F6"
      stroke="#930D0D"
      strokeWidth="3"
    />
  </svg>
);

export default SearchIcon;
