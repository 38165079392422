import React, { useEffect, useState } from "react";
import cn from "classnames";
import Layout from "../components/Layout/Layout";
import Navbar from "../components/Navbar/Navbar";
import SearchIcon from "../icons/SearchIcon";
import { submissionsApi } from "../utils/api";
import styles from "./ThreadPage.module.scss";
import ThreadPageItem from "./ThreadPageItem/ThreadPageItem";
import { useLocation } from "react-router-dom";

const ThreadPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [defaultSearchValue, setDefaultSearchValue] = useState("");
  const { submissions } = submissionsApi;
  const { search } = useLocation();
  const urlSearchValue = search.replace("?search_text=", "");

  useEffect(() => {
    if (search) {
      setSearchValue(urlSearchValue);
      setShowSearch(true);
      setDefaultSearchValue(urlSearchValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredSubmissions = submissions.filter(
    ({ author, submissionTitle, callTag }) =>
      author
        .toLocaleLowerCase()
        .includes(searchValue.trim().toLocaleLowerCase()) ||
      submissionTitle
        .toLocaleLowerCase()
        .includes(searchValue.trim().toLocaleLowerCase()) ||
      (callTag &&
        callTag
          .toLocaleLowerCase()
          .includes(searchValue.trim().toLocaleLowerCase()))
  );

  return (
    <Layout>
      <Navbar />

      <div className={styles.wrapper}>
        <div>
          <div
            className={cn(styles.searchWrapper, {
              [styles.searchWrapperClosed]: !showSearch,
            })}
          >
            {showSearch && (
              <span className={styles.searchBarWrapper}>
                <SearchIcon className={styles.searchIconInsideInput} />
                <input
                  className={cn(styles.searchBar, {
                    [styles.open]: !!showSearch,
                  })}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onBlur={() => setShowSearch(false)}
                  defaultValue={defaultSearchValue}
                />
              </span>
            )}
            <button
              className={cn(styles.searchButton, {
                [styles.openSearchBar]: !!showSearch,
              })}
              onClick={() => setShowSearch(true)}
            >
              <SearchIcon />
            </button>
          </div>
          <ul className={styles.resultsList}>
            {filteredSubmissions.length > 0 &&
              filteredSubmissions.map((sub, idx) => (
                <span key={idx}>
                  <ThreadPageItem submission={sub} />
                </span>
              ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default ThreadPage;
