import React from "react";

type PlayButtonIconProps = {
  className?: string;
};

const PlayButtonIcon = ({ className }: PlayButtonIconProps) => (
  <svg
    className={className}
    width="41"
    height="59"
    viewBox="0 0 41 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_359_4)">
      <path
        d="M37.3134 21.7519C41.5684 26.0405 41.5684 32.9581 37.3134 37.2467L19.5588 55.1419C12.6418 62.1137 0.75 57.2154 0.75 47.3945V11.6041C0.75 1.78322 12.6418 -3.11507 19.5588 3.85666L37.3134 21.7519Z"
        fill="#930D0D"
      />
    </g>
    <defs>
      <clipPath id="clip0_359_4">
        <rect width="41" height="59" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PlayButtonIcon;
